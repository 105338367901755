<template>
  <DashboardCard>
    <template #title>User Profile</template>
    <b-list-group flush>
      <b-list-group-item class="item">
        <div class="label">Display Name</div>
        <div>{{ user?.displayName }}</div>
        <div v-if="!user?.displayName" class="text-muted">
          <b-link :to="isDemo ? '#' : '/profile'"> Set your display name </b-link>
          <b-tooltip v-if="isDemo" target="btn-set-display-name" triggers="hover focus">
            Disabled in the demo
          </b-tooltip>
        </div>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Email Address</div>
        <div>{{ user?.email }}</div>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Address</div>
        <div>{{ profile?.address?.formatted }}</div>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Local Government Area</div>
        <div v-if="lga">
          {{ lga?.lga_name || lga?.properties?.lga_name }}
        </div>
        <div v-if="!lga">
          <b-link :to="isDemo ? '#' : '/welcome'" id="btn-set-lga"> Set my LGA </b-link>
          <b-tooltip v-if="isDemo" target="btn-set-lga" triggers="hover focus">
            Disabled in the demo
          </b-tooltip>
        </div>
      </b-list-group-item>
      <b-list-group-item
        class="text-muted text-center"
        :to="isDemo ? '#' : '/profile'"
        id="btn-edit-profile"
      >
        Edit Profile
      </b-list-group-item>
      <b-tooltip target="btn-edit-profile" triggers="hover focus" :disabled="!isDemo">
        Disabled in the demo
      </b-tooltip>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import { mapGetters } from "vuex";
import { auth } from "@/firebase/config";

export default {
  name: "dashboard-card-user-subscriptions",

  components: {
    DashboardCard,
  },

  mixins: [DashboardCard],

  data: () => ({
    lga: null,
  }),

  computed: {
    user() {
      return auth.currentUser;
    },

    ...mapGetters("userData", ["profile"]),
  },

  watch: {
    profile(profile) {
      // do naught
      if (profile) this.refreshProfile(profile);
    },
  },

  mounted() {
    if (this.profile) {
      this.refreshProfile(this.profile);
    }
  },

  methods: {
    subscribe() {
      // nothing to do
    },

    async refreshProfile(profile) {
      console.debug("[DashboardCardUserProfile] refreshProfile", profile);
      if (profile?.lga) {
        this.lga = profile.lga;
      } else if (profile?.address?.latLng) {
        if (this.$store.getters["lgaData/cached"]) {
          const lga = await this.$store.dispatch(
            "lgaData/lgaFromLatLng",
            profile.address.latLng
          );
          this.lga = lga?.properties;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  .label {
    color: #212529;
    font-size: 0.9rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .badge {
    font-size: 0.9rem;
    padding: 6px 11px;
  }
}
</style>
