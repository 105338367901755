<template>
  <b-modal
    scrollable
    ref="modal"
    id="demoInfo"
    ok-only
    centered
    size="lg"
    no-body
    no-close-on-backdrop
    @ok="$router.push('/help/getting-started')"
  >
    <template #modal-header>
      <div class="d-flex text-center justify-content-between align-items-start w-100">
        <h4 class="mb-0 text-center flex-grow-1">Welcome to the Clozure Demo</h4>
        <b-button-close @click="$refs.modal.hide()" class="float-right"></b-button-close>
      </div>
    </template>

    <b-card-body class="py-2 px-3 pb-0">
      <p>Two personas have been prepared for you to explore the demo:</p>

      <dl class="mx-1">
        <dt>Alex Smith: Public User</dt>
        <dd>
          Alex Smith is a member of the public who can report incidents, receive closure
          updates, and ask for directions.
        </dd>
        <dt>Council Staff: Forbes LGA</dt>
        <dd>
          Step into the shoes of a staff member, responsible for marking road closures and
          fielding incident reports.
        </dd>
      </dl>

      <p>
        Feel free to explore the various features and functionalities available to each
        user profile. You can report incidents, subscribe to closures, view closure
        history, generate social media posts, and much more.
      </p>

      <p>
        Please note that the demo environment is restricted to the Local Government Area
        of Forbes.
        <help-more-info placement="right" triggers="click blur">
          <p class="lead"><strong>Why Forbes?</strong></p>

          <p>
            Both of us (the co-founders) have roots in this beautiful town. We know the
            challenges of living in a rural area.
          </p>

          <p>
            That's why we chose Forbes as the backdrop for our demo. We want to showcase
            how Clozure can make a real difference in managing road closures and keeping
            the community informed.
          </p>

          <p class="mb-0">
            We understand the importance of accurate and timely information, especially in
            rural areas like Forbes, recently devastated by floods that saw some areas
            completely marooned for weeks at a time.
          </p>
        </help-more-info>
      </p>

      <p>
        If you have any questions or need assistance, please
        <b-link href="https://www.clozure.com.au/#contact" target="_blank">
          get in touch.
        </b-link>
      </p>

      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style="gap: 10px"
      >
        <b-img
          width="180px"
          alt="Clozure Logo"
          src="/img/icons/logo-with-text-horizontal-256.png"
        ></b-img>
        <p class="mb-0 lead text-center">
          Enjoy your experience in the Clozure Demo Environment!
        </p>
      </div>
    </b-card-body>

    <template #modal-footer="{ ok }">
      <div class="w-100 justify-content-between align-items-center d-flex">
        <!-- <b-form-checkbox
          v-model="showDemoInfoOnStartup"
          :value="true"
          :unchecked-value="false"
        >
          Show this window when loading the demo
        </b-form-checkbox> -->
        <div class="d-flex flex-grow-1"></div>
        <b-button @click="ok" variant="primary">Get Started</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "demo-info-modal",

  props: {
    personas: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    hideDemoInfoOnStartup: {
      get() {
        return localStorage.getItem("hideDemoInfoOnStartup") === "true";
      },
      set(v) {
        localStorage.setItem("hideDemoInfoOnStartup", v);
      },
    },

    showDemoInfoOnStartup: {
      get() {
        return !this.hideDemoInfoOnStartup;
      },
      set(v) {
        this.hideDemoInfoOnStartup = !v;
      },
    },
  },

  mounted() {
    if (!this.hideDemoInfoOnStartup) {
      // disabled due to stealing focus from the new sales website demo iframe
      // this.$bvModal.show("demoInfo");
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-header,
/deep/ .modal-footer {
  background: #f3f3f3;
}
</style>
