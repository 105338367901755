<script>
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";
import { mapGetters } from "vuex";
import { db } from "@/firebase/config";

export default {
  name: "staff-onboarding-checkout-sessions",

  data: () => ({
    quoteRequest: null,
    quoteRequestUnsub: null,
  }),

  computed: {
    ...mapGetters("auth", ["user", "staff"]),

    hasOnboardingQuoteRequest() {
      return !!this.quoteRequest;
    },

    hasReviewableQuote() {
      return !!this.quoteRequest?.quote?.id;
    },
  },

  watch: {
    staff(staff) {
      return staff ? this.subscribeQuoteRequests() : this.unsubQuoteRequests();
    },
  },

  async mounted() {
    console.debug(`[StaffOnboardingQuoteRequests] mounted`);
    if (this.staff) this.subscribeQuoteRequests();
  },

  beforeDestroy() {
    this.unsubQuoteRequests();
  },

  methods: {
    subscribeQuoteRequests() {
      this.unsubQuoteRequests();

      console.debug(`[StaffOnboardingQuoteRequests] subscribe`, this.user.email);

      const q = query(
        collection(db, "quote_requests"),
        // where("email", "==", this.user?.email),
        where("lgaEmailSuffix", "==", this.user?.email.split("@")[1]),
        limit(1)
      );

      const onSnap = (snapshot) => {
        this.quoteRequest = snapshot.size === 0 ? null : snapshot.docs[0].data();
      };

      const onError = (e) =>
        console.error(`Failed to subscribe to checkout session data: ${e.message}`);

      this.quoteRequestUnsub = onSnapshot(q, onSnap, onError);
    },

    unsubQuoteRequests() {
      this.quoteRequest = null;

      if (this.quoteRequestUnsub) {
        this.quoteRequestUnsub();
        this.quoteRequestUnsub = null;
        console.debug(`[StaffOnboardingQuoteRequests] unsubscribe`);
      }
    },
  },
};
</script>
