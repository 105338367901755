<template>
  <div class="d-flex flex-column justify-content-center" style="gap: 10px">
    <b-button variant="light" :to="signinLink">Continue with email link</b-button>
    <b-button variant="light" @click="login('google')">Continue with Google</b-button>
    <b-button variant="light" @click="login('microsoft')">
      Continue with Microsoft
    </b-button>
    <b-button variant="light" @click="login('facebook')">Continue with Facebook</b-button>
    <!-- <b-button variant="light" @click="login('apple')">Continue with Apple</b-button> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "oauth-component",

  computed: {
    signinLink() {
      let link = `/signin-link`;

      if (this.$route.query.redirect) {
        link += `?redirect=${this.$route.query.redirect}`;
      }

      return link;
    },
  },

  methods: {
    ...mapActions("app", ["setLoading"]),

    async login(provider) {
      return this.$store.dispatch(`auth/${provider}Login`);

      // if (provider === "microsoft") {
      //   return this.$store.dispatch(`auth/${provider}Login`);
      // }

      // try {
      //   await this.setLoading(true);
      //   const additionalUserInfo = await this.$store.dispatch(`auth/${provider}Login`);
      //   if (additionalUserInfo.isNewUser) {
      //     this.$router.replace("/welcome");
      //   } else {
      //     this.$router.replace("/dashboard");
      //   }
      // } catch (e) {
      //   this.errorToast(`Failed to sign in: ${e.message}`, { title: "Sign In Error" });
      // } finally {
      //   await this.setLoading(false);
      // }
    },
  },
};
</script>

<style scoped></style>
